import type { PlatformId, SiteId } from 'frontend-platform_detection';
import { FunctionComponent } from 'react';
import { svgsConfiguration } from './config';
import type { Contextual } from '../../../services/types';
import { LandingML, LandingMP } from '../../components/svg/landing';

export type SvgSelectorProps = {
  svgType: 'landing' | 'congrats'
  platformId: PlatformId
  siteId: SiteId
  contextual?: Contextual
}
export const svgSelector = ({ svgType, platformId, siteId, contextual = 'normal' }: SvgSelectorProps): FunctionComponent => {
  const defaultSvg = platformId === 'ML' ? LandingML : LandingMP;

  // eslint-disable-next-line security/detect-object-injection
  const filteredSvg = svgsConfiguration[svgType][platformId]!
    .find(svgObject => (svgObject.siteId === siteId && svgObject.contextual === contextual)
      || (svgObject.siteId === siteId && svgObject.contextual === '*')
       || (svgObject.siteId === '*' && svgObject.contextual === contextual)
        || (svgObject.siteId === '*' && svgObject.contextual === '*'));

  return filteredSvg?.component || defaultSvg;
};
