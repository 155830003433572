import type { FunctionComponent } from 'react';
import { Title, Text } from '@andes/typography';
import { ReactNode } from 'react';
import type { LandingWordings } from '../../wordings/index';
import type { CaptionColor } from '../../contexts/landingConfiguration';

export type CommonLandingProps = {
  children?: ReactNode
  Icon?: FunctionComponent
  landingWordings: LandingWordings
  showCaption?: boolean
  captionColor?: CaptionColor
}

const CommonLanding: FunctionComponent<CommonLandingProps> = (props: CommonLandingProps) => {
  const { children, Icon, landingWordings, showCaption = true, captionColor = 'primary' } = props;

  return (
    <>
      <div className="landing-container">
        {Icon && <Icon />}
        <Title component="h1" className="landing-container__title" data-cy="title">{landingWordings.title}</Title>
        { showCaption ? <Text color={captionColor} className="landing-container__caption" data-cy="caption">{landingWordings.caption}</Text> : null }
      </div>
      { children }
    </>
  );
};

export default CommonLanding;
