import { FunctionComponent } from 'react';
import { Style } from 'nordic/style';

type StyleAssetProps = {
  platformId: string;
  viewName: string;
}

const StyleAsset: FunctionComponent<StyleAssetProps> = ({ platformId, viewName }: StyleAssetProps) => (
  <Style href={`${viewName}${platformId === 'MP' ? '-mp' : '-ml'}.css`} />
);

export default StyleAsset;
