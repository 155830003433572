
import classNames from 'classnames';
import { PageProvider } from '../../../contexts/PageContext';
import StyleAsset from '../../style-asset';
import type { PageContextType } from '../../../contexts/PageContext';
import CenterCard from '../../center-card';

export type CommonLayoutProps = PageContextType & {
  children: JSX.Element[] | JSX.Element
  pageName: string
  className?: string
}


export const CommonLayout = (props: CommonLayoutProps): JSX.Element => {
  const { children, pageName, className = 'card', platformId, siteId, isWebview, isMobile, isDesktop } = props;

  const classNameMobile = `${className}--mobile`;
  const cardClassName = classNames(className, { [classNameMobile]: isMobile });

  return (
    <PageProvider
      platformId={platformId}
      siteId={siteId}
      isWebview={isWebview}
      isDesktop={isDesktop}
      isMobile={isMobile}
    >
      <CenterCard padding={0} className={cardClassName}>
        <StyleAsset viewName={pageName} platformId={platformId} />
        <>
          {children}
        </>
      </CenterCard>
    </PageProvider>
  );
};
