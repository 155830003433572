/* eslint-disable max-len */
import { FunctionComponent } from 'react';

const LandingPJMP: FunctionComponent = () => (
  <svg width="185" height="161" viewBox="0 0 185 161" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path d="M131.562 144.591C150.346 135.411 172.98 115.733 181.03 88.9781C188.516 64.0958 181.522 42.9641 170.772 27.8393L170.073 26.8719C169.487 26.0726 168.89 25.2909 168.285 24.5271C156.056 9.08637 120.352 -12.363 99.7098 12.0527C98.2649 13.7619 96.9119 15.4188 95.6354 17.0259L94.5597 18.3913L93.5193 19.7326L92.5122 21.0501L91.5362 22.3443L90.5892 23.6153L89.2184 25.4794L87.9008 27.2934L83.8011 32.9932L82.6312 34.6035L81.86 35.6524L81.0933 36.6822L80.329 37.693L79.5649 38.6853C79.4374 38.8491 79.3099 39.0122 79.1823 39.1746L78.4145 40.1396C71.742 48.4263 64.4967 54.716 50.4413 59.9883L49.6228 60.2913C22.0834 70.3549 1.32818 87.1808 0.0848759 108.424C-1.22134 130.728 12.7252 144.624 29.7656 151.863C65.7216 167.139 95.3877 162.273 131.562 144.591Z" fill="#EEEEEE" />
    <path d="M152.5 16V129H13.5V16H152.5Z" fill="#5AD2FF" stroke="white" strokeWidth="3" />
    <path d="M12 128.5C12 129.605 12.8954 130.5 14 130.5H152C153.105 130.5 154 129.605 154 128.5V16.5C154 15.3954 153.105 14.5 152 14.5H14C12.8954 14.5 12 15.3954 12 16.5V128.5Z" stroke="#333333" strokeWidth="1.5" />
    <path d="M32 115.935V139.774C32 141.28 33.2225 142.5 34.7291 142.5H130.271C131.778 142.5 133 141.28 133 139.774V4.22397C133 2.71853 131.778 1.5 130.271 1.5H34.7291C33.2225 1.5 32 2.71853 32 4.22397V77.7893V85.0543V90.5039V115.935Z" fill="white" />
    <path d="M33.1707 136H33V142H133V136H132.829C132.417 137.165 131.306 138 130 138H36C34.6938 138 33.5825 137.165 33.1707 136Z" fill="#EEEEEE" />
    <path d="M32 115.935V139.774C32 141.28 33.2225 142.5 34.7291 142.5H130.271C131.778 142.5 133 141.28 133 139.774V4.22397C133 2.71853 131.778 1.5 130.271 1.5H34.7291C33.2225 1.5 32 2.71853 32 4.22397V77.7893V85.0543V90.5039V115.935Z" stroke="#333333" strokeWidth="1.5" />
    <path d="M60 20.5H125" stroke="#333333" strokeWidth="1.5" />
    <path d="M106 30.5L41 30.5" stroke="#333333" strokeWidth="1.5" />
    <path d="M119 40.5L41 40.5" stroke="#333333" strokeWidth="1.5" />
    <path d="M53 20.5H41" stroke="#333333" strokeWidth="1.5" />
    <path d="M113 30.5L125 30.5" stroke="#333333" strokeWidth="1.5" />
    <path d="M165.406 130.859H81.3648C76.7474 130.859 73 127.02 73 122.285V70.5739C73 65.839 76.7474 62 81.3648 62H165.406C170.025 62 173.77 65.839 173.77 70.5739V122.285C173.77 127.02 170.025 130.859 165.406 130.859Z" fill="white" />
    <path d="M164.272 100.602V115.456C164.272 116.022 163.824 116.481 163.272 116.481H146.668C146.116 116.481 145.668 116.022 145.668 115.456V100.602C145.668 100.036 146.116 99.5771 146.668 99.5771H163.272C163.824 99.5771 164.272 100.036 164.272 100.602Z" fill="#5AD2FF" />
    <path d="M128.297 77.4524H163.04" stroke="#333333" strokeWidth="1.2" strokeLinecap="square" />
    <path d="M128.297 86.451H163.04" stroke="#333333" strokeWidth="1.2" strokeLinecap="square" />
    <path d="M128.297 95.6375H163.04" stroke="#333333" strokeWidth="1.2" strokeLinecap="square" />
    <path d="M165.406 130.859H81.3648C76.7474 130.859 73 127.02 73 122.285V70.5739C73 65.839 76.7474 62 81.3648 62H165.406C170.025 62 173.77 65.839 173.77 70.5739V122.285C173.77 127.02 170.025 130.859 165.406 130.859Z" stroke="#333333" strokeWidth="1.2" />
    <path d="M148.356 101.234V111.322" stroke="#333333" strokeWidth="1.2" />
    <path d="M151.638 101.234V111.322" stroke="#333333" strokeWidth="1.2" />
    <path d="M154.918 101.234V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M158.199 101.234V111.322" stroke="#333333" strokeWidth="1.2" />
    <path d="M161.479 101.234V114.685V101.234Z" fill="black" />
    <path d="M161.479 101.234V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M148.356 113.004V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M151.638 113.004V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M158.199 113.004V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M114.676 81.5523V109.464C114.676 110.03 114.228 110.489 113.676 110.489H86.4453C85.893 110.489 85.4453 110.03 85.4453 109.464V81.5523C85.4453 80.9863 85.893 80.5273 86.4453 80.5273H113.676C114.228 80.5273 114.676 80.9863 114.676 81.5523Z" fill="#5AD2FF" />
    <path d="M108.669 93.1858C108.669 98.0569 104.815 102.009 100.06 102.009C95.3058 102.009 91.4521 98.0569 91.4521 93.1858C91.4521 88.3147 95.3058 84.3623 100.06 84.3623C104.815 84.3623 108.669 88.3147 108.669 93.1858Z" fill="white" stroke="#333333" strokeWidth="1.2" />
    <path d="M115.657 111.24C112.2 106.207 106.524 102.891 100.061 102.891C93.5974 102.891 87.9207 106.207 84.4639 111.24" fill="white" />
    <path d="M115.657 111.24C112.2 106.207 106.524 102.891 100.061 102.891C93.5974 102.891 87.9207 106.207 84.4639 111.24" stroke="#333333" strokeWidth="1.2" />
    <rect x="82.4453" y="77.4526" width="35.2305" height="36.1113" rx="2" stroke="#333333" strokeWidth="1.2" />
  </svg>
);

export const LandingPJMPSVG: FunctionComponent = () => (<LandingPJMP />);
