import type { FC, ReactNode } from 'react';
import { Card } from '@andes/card';
import classnames from 'classnames';

type CenterCardProps = {
  children: ReactNode
  className: string
  padding?: 0 | 16 | 24 | 32 | 40 | 48
}
const CenterCard: FC<CenterCardProps> = ({ children, className, padding = 48 }) => {
  const contentClassName = classnames(
    'center-card__content',
    `center-card__content--padding-${padding}`,
    className,
  );

  return (
    <Card className={contentClassName}>
      {children}
    </Card>
  );
};
export default CenterCard;
