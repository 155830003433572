export type LandingConfiguration = {
  showCompanyButton: boolean,
  showTycCheckbox: boolean,
  caption: {
    show?: boolean,
    color?: CaptionColor,
  },
}

export type CaptionColor = 'primary' | 'secondary' | 'disabled' | 'inverted' | 'link' | 'negative' | 'caution' | 'positive';

export const landingConfiguration: LandingConfiguration = {
  showCompanyButton: true,
  showTycCheckbox: true,
  caption: {
    show: true,
    color: 'primary',
  },
};

