type NativeAction = {
  method: string;
  args: Record<string, unknown>;
};

export type PushOptions = {
  // eslint-disable-next-line camelcase
  screens_group?: string
  replace?: boolean
  transition?: 'push' | 'present'
}

export type PopOptions = {
  screens?: number,
  isHistoryEnabled?: boolean,
  animated?: boolean
}

export const executePush = (deeplink: string, options: PushOptions): void => {
  if (window && window.MobileWebKit) {
    window.MobileWebKit.navigation.push(deeplink, options);
  }
};

export const executePop = (options: PopOptions): void => {
  if (window && window.MobileWebKit) {
    window.MobileWebKit.navigation.pop(options);
  }
};

export const executeReload = (): void => {
  if (window && window.MobileWebKit) {
    window.MobileWebKit.navigation.reload();
  }
};

export const handleExecuteNative = (
  condition: boolean,
  link: string,
  method: 'push' | 'pop' = 'push',
  options: PushOptions | PopOptions,
  // TODO: poner `callback` al final de la lista y inicializar `isRegulatedStep` con false
  callback?: () => void,
  isRegulatedStep?: boolean,
): void => {
  if (isRegulatedStep) return;

  if (condition) {
    if (method === 'push') executePush(link, options as PushOptions);
    if (method === 'pop') executePop(options as PopOptions);
  } else {
    callback?.();
    window.location.assign(link);
  }
};

export const handleReloadNative = (condition: boolean): void => {
  if (!condition) return;

  executeReload();
};
