/* eslint-disable jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for, react/no-danger */
import { FunctionComponent, memo, ChangeEvent, useRef, useEffect } from 'react';
import { Checkbox } from '@andes/checkbox';
import classNames from 'classnames';
import { handleExecuteNative } from '../../utils/executeNative';
import { TyCUrls } from '../../../services/url';

export type Props = {
  descriptionHtml?: boolean
  isChecked?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>, customName?: string) => void
  customClass?: string
  customName?: string
  isWebview: boolean
  tycWording: string
  tycUrls: TyCUrls
  error?: boolean
  hasBoxError?: boolean
}

export const DefaultCheckbox: FunctionComponent<Props> = memo(({
  tycWording,
  tycUrls,
  descriptionHtml = false,
  isChecked,
  onChange,
  customClass,
  customName = 'terms-and-conds',
  isWebview,
  error = false,
  hasBoxError = true,
}) => {
  const checkboxClassName = classNames('custom-checkbox', customClass, { 'custom-checkbox--box-error': hasBoxError && error });

  const ref = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    const container = ref.current!;

    Object.keys(tycUrls).forEach(key => {
      const anchor = container?.querySelector(`#${key}`) as HTMLAnchorElement;
      const url = tycUrls[key as keyof TyCUrls];

      if (anchor) {
        anchor.onclick = (e) => {
          e.preventDefault();
          handleExecuteNative(isWebview, url, 'push', {});
        };
      }
    });
  }, [isWebview, tycUrls]);

  return (
    <div className={checkboxClassName}>
      <Checkbox
        id={customName}
        srLabel={descriptionHtml ? 'checkbox' : tycWording}
        inputProps={{ name: customName }}
        data-cy="checkbox"
        checked={isChecked}
        highlight
        onChange={onChange}
        label={descriptionHtml ? '' : tycWording}
        error={error}
      />

      {descriptionHtml && (
        <label
          ref={ref}
          className="custom-checkbox__description"
          htmlFor={customName}
          dangerouslySetInnerHTML={{
            __html: tycWording,
          }}
        />
      )}
    </div>
  );
});
