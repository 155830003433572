import type { FunctionComponent } from 'react';
import type { PlatformId, SiteId } from 'frontend-platform_detection';
import type { Contextual } from '../../../services/types';
import { LandingPJMPSVG } from '../../components/svg/contextuals/company/landing/LandingMP';
import WhatsappLandingSVG from '../../components/svg/contextuals/whatsapp/landing';
import { OperatorML, OperatorMP } from '../../components/svg/contextuals/operator/landing';
import { LandingPJMLSVG } from '../../components/svg/contextuals/company/landing/LandingML';
import { LandingML, LandingMP } from '../../components/svg/landing';

export type SvgConfigurationType = {
  landing: {
    [key in PlatformId]?: Array<{siteId: SiteId | '*', contextual: Contextual | '*', component: FunctionComponent}>
  }
  congrats: {
    [key in PlatformId]?: Array<{siteId: SiteId | '*', contextual: Contextual | '*', component: FunctionComponent}>
  }
}

export const svgsConfiguration: SvgConfigurationType = {
  landing: {
    MP: [
      {
        siteId: '*',
        contextual: 'company',
        component: LandingPJMPSVG,
      },
      {
        siteId: '*',
        contextual: 'whatsapp',
        component: WhatsappLandingSVG,
      },
      {
        siteId: '*',
        contextual: 'operator',
        component: OperatorMP,
      },
      {
        siteId: '*',
        contextual: 'valeras',
        component: LandingPJMPSVG,
      },
      {
        siteId: '*',
        contextual: '*',
        component: LandingMP,
      },
    ],
    ML: [
      {
        siteId: '*',
        contextual: 'company',
        component: LandingPJMLSVG,
      },
      {
        siteId: '*',
        contextual: 'operator',
        component: OperatorML,
      },
      {
        siteId: '*',
        contextual: '*',
        component: LandingML,
      },
      {
        siteId: '*',
        contextual: 'whatsapp',
        component: WhatsappLandingSVG,
      },
    ],
    TC: [
      {
        siteId: '*',
        contextual: 'vis',
        component: LandingML,
      },
    ],
    PI: [
      {
        siteId: '*',
        contextual: 'vis',
        component: LandingML,
      },
    ],
    MT: [
      {
        siteId: '*',
        contextual: 'vis',
        component: LandingML,
      },
    ],
  },
  congrats: {
    MP: [],
    ML: [],
    TC: [],
    PI: [],
    MT: [],
  },
};
