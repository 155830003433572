/* eslint-disable max-len */
import { FunctionComponent } from 'react';

const LandingPJML: FunctionComponent = () => (
  <svg width="320" height="161" viewBox="0 0 320 161" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <rect y="1" width="320" height="160" fill="white" />
    <path d="M199.562 144.591C218.346 135.411 240.98 115.733 249.03 88.9781C256.516 64.0958 249.522 42.9641 238.772 27.8393L238.073 26.8719C237.487 26.0726 236.89 25.2909 236.285 24.5271C224.056 9.08637 188.352 -12.363 167.71 12.0527C166.265 13.7619 164.912 15.4188 163.635 17.0259L162.56 18.3913L161.519 19.7326L160.512 21.0501L159.536 22.3443L158.589 23.6153L157.218 25.4794L155.901 27.2934L151.801 32.9932L150.631 34.6035L149.86 35.6524L149.093 36.6822L148.329 37.693L147.565 38.6853C147.437 38.8491 147.31 39.0122 147.182 39.1746L146.415 40.1396C139.742 48.4263 132.497 54.716 118.441 59.9883L117.623 60.2913C90.0834 70.3549 69.3282 87.1808 68.0849 108.424C66.7787 130.728 80.7252 144.624 97.7656 151.863C133.722 167.139 163.388 162.273 199.562 144.591Z" fill="#EEEEEE" />
    <path d="M220.5 16V129H81.5V16H220.5Z" fill="#FFE600" stroke="white" strokeWidth="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M80 128.5C80 129.605 80.8954 130.5 82 130.5H220C221.105 130.5 222 129.605 222 128.5V16.5C222 15.3954 221.105 14.5 220 14.5H82C80.8954 14.5 80 15.3954 80 16.5V128.5Z" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M100 115.935V139.774C100 141.28 101.222 142.5 102.729 142.5H198.271C199.778 142.5 201 141.28 201 139.774V4.22397C201 2.71853 199.778 1.5 198.271 1.5H102.729C101.222 1.5 100 2.71853 100 4.22397V77.7893V85.0543V90.5039V115.935Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M101.171 136H101V142H201V136H200.829C200.417 137.165 199.306 138 198 138H104C102.694 138 101.583 137.165 101.171 136Z" fill="#EEEEEE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M100 115.935V139.774C100 141.28 101.222 142.5 102.729 142.5H198.271C199.778 142.5 201 141.28 201 139.774V4.22397C201 2.71853 199.778 1.5 198.271 1.5H102.729C101.222 1.5 100 2.71853 100 4.22397V77.7893V85.0543V90.5039V115.935Z" stroke="#333333" strokeWidth="1.5" />
    <path d="M128 27.2721H193" stroke="#333333" strokeWidth="1.5" />
    <path d="M174 37.9594L109 37.9594" stroke="#333333" strokeWidth="1.5" />
    <path d="M187 48.6469L109 48.6469" stroke="#333333" strokeWidth="1.5" />
    <path d="M121 27.2721H109" stroke="#333333" strokeWidth="1.5" />
    <path d="M181 37.9594L193 37.9594" stroke="#333333" strokeWidth="1.5" />
    <path d="M233.406 130.859H149.365C144.747 130.859 141 127.02 141 122.285V70.5739C141 65.839 144.747 62 149.365 62H233.406C238.025 62 241.77 65.839 241.77 70.5739V122.285C241.77 127.02 238.025 130.859 233.406 130.859Z" fill="white" />
    <path d="M232.272 100.602V115.456C232.272 116.022 231.824 116.481 231.272 116.481H214.668C214.116 116.481 213.668 116.022 213.668 115.456V100.602C213.668 100.036 214.116 99.5771 214.668 99.5771H231.272C231.824 99.5771 232.272 100.036 232.272 100.602Z" fill="#FFE600" />
    <path d="M196.297 77.4524H231.04" stroke="#333333" strokeWidth="1.2" strokeLinecap="square" />
    <path d="M196.297 86.451H231.04" stroke="#333333" strokeWidth="1.2" strokeLinecap="square" />
    <path d="M196.297 95.6375H231.04" stroke="#333333" strokeWidth="1.2" strokeLinecap="square" />
    <path fillRule="evenodd" clipRule="evenodd" d="M233.406 130.859H149.365C144.747 130.859 141 127.02 141 122.285V70.5739C141 65.839 144.747 62 149.365 62H233.406C238.025 62 241.77 65.839 241.77 70.5739V122.285C241.77 127.02 238.025 130.859 233.406 130.859Z" stroke="#333333" strokeWidth="1.2" />
    <path d="M216.355 101.234V111.322" stroke="#333333" strokeWidth="1.2" />
    <path d="M219.637 101.234V111.322" stroke="#333333" strokeWidth="1.2" />
    <path d="M222.918 101.234V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M226.199 101.234V111.322" stroke="#333333" strokeWidth="1.2" />
    <path d="M229.48 101.234V114.685V101.234Z" fill="black" />
    <path d="M229.48 101.234V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M216.355 113.004V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M219.637 113.004V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M226.199 113.004V114.685" stroke="#333333" strokeWidth="1.2" />
    <path d="M182.676 81.5523V109.464C182.676 110.03 182.228 110.489 181.676 110.489H154.445C153.893 110.489 153.445 110.03 153.445 109.464V81.5523C153.445 80.9863 153.893 80.5273 154.445 80.5273H181.676C182.228 80.5273 182.676 80.9863 182.676 81.5523Z" fill="#FFE600" />
    <path d="M176.67 93.1858C176.67 98.0569 172.816 102.009 168.061 102.009C163.307 102.009 159.453 98.0569 159.453 93.1858C159.453 88.3147 163.307 84.3623 168.061 84.3623C172.816 84.3623 176.67 88.3147 176.67 93.1858Z" fill="white" stroke="#333333" strokeWidth="1.2" />
    <path d="M183.658 111.24C180.201 106.207 174.525 102.891 168.062 102.891C161.598 102.891 155.922 106.207 152.465 111.24" fill="white" />
    <path d="M183.658 111.24C180.201 106.207 174.525 102.891 168.062 102.891C161.598 102.891 155.922 106.207 152.465 111.24" stroke="#333333" strokeWidth="1.2" />
    <rect x="150.445" y="77.4526" width="35.2305" height="36.1113" rx="2" stroke="#333333" strokeWidth="1.2" />
  </svg>
);

export const LandingPJMLSVG: FunctionComponent = () => (<LandingPJML />);
