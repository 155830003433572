/* eslint-disable react/no-danger */
import type { FunctionComponent, ChangeEvent } from 'react';
import { useState } from 'react';
import { Page } from 'nordic/page';
import { AndesProvider } from '@andes/context';
import { Head } from 'nordic/head';
import { LandingWordings } from '../../wordings/index';
import type { CommonProps } from '../../helpers/commonProps';
import { GATrack, MelidataTrack } from '../../../services/Track';
import { CommonLayout } from '../layouts';
import CommonLanding from '../common-landing';
import { svgSelector } from '../../helpers/svg-selector';
import { type LandingContexProps, useLanding } from '../../contexts/LandingContext';
import { DefaultCheckbox } from '../DefaultCheckbox';
import { DefaultButton } from '../DefaultButton';
import { LandingConfiguration } from '../../contexts/landingConfiguration';
import { handleExecuteNative } from '../../utils/executeNative';
import { TyCUrls } from '../../../services/url';

export type CommonLandingViewProps = {
  pageName: string,
  wordings: {
    landingWordings: LandingWordings
    tycWordings?: string
    customHeadTitle?: string
  },
  defaultProps: CommonProps
  locale: string
  urls: {
    hub: string
    company?: string
    tycUrls: TyCUrls
  }
  tracks: {
    melidata: MelidataTrack
    analytics: GATrack
  },
  config?: Partial<LandingConfiguration>
};

const CommonLandingView: FunctionComponent<CommonLandingViewProps> = ({
  pageName,
  wordings,
  defaultProps,
  locale,
  urls,
  tracks,
  config,
}) => {
  const { contextual, platformId, siteId, isWebview } = defaultProps;
  const { landingWordings, customHeadTitle } = wordings;
  const { landingConfiguration, setIsChecked, isChecked } = useLanding() as LandingContexProps;
  const { showTycCheckbox, showCompanyButton, caption } = landingConfiguration;
  const [checkboxError, setCheckboxError] = useState(false);
  const Icon = svgSelector({ svgType: 'landing', platformId, siteId, contextual });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.blur();
    setIsChecked(e.target.checked);
  };

  const handleRedirect = (url: string) => {
    if (showTycCheckbox && !isChecked) {
      setCheckboxError(true);
      return;
    }

    setCheckboxError(false);
    handleExecuteNative(isWebview, url, 'push', {});
  };

  const stateProps = { wordings, defaultProps, urls, tracks, config, pageName, locale };

  return (
    <AndesProvider locale={locale}>
      <Page
        name={pageName}
        className={`landing ${pageName}`}
        state={{ ...stateProps }}
        styles={false}
        melidata={tracks.melidata}
        analytics={tracks.analytics}
      >
        <CommonLayout {...defaultProps} pageName={pageName}>
          <Head>
            <title>{customHeadTitle || landingWordings.title}</title>
          </Head>
          <CommonLanding
            Icon={Icon}
            landingWordings={wordings.landingWordings}
            showCaption={caption.show}
            captionColor={caption.color}
          >
            { showTycCheckbox && (
              <DefaultCheckbox
                tycUrls={urls.tycUrls}
                isWebview={defaultProps.isWebview}
                tycWording={wordings.tycWordings!}
                descriptionHtml
                isChecked={isChecked}
                onChange={handleOnChange}
                error={checkboxError}
              />
            )}

            <div className="buttons-container">
              <DefaultButton
                id="first-button"
                onClick={() => handleRedirect(urls.hub)}
                wording={landingWordings.button}
              />
              { showCompanyButton && (
                <DefaultButton
                  id="second-button"
                  onClick={() => handleRedirect(urls.company!)}
                  wording={landingWordings.go_register_company}
                  hierarchy="quiet"
                />
              )}
            </div>
          </CommonLanding>
        </CommonLayout>
      </Page>
    </AndesProvider>
  );
};

export default CommonLandingView;
