import { createContext, useContext } from 'react';
import type { PlatformId, SiteId } from 'frontend-platform_detection';
import type { Contextual, Entity } from '../../services/types';

export type PageContextType = {
  platformId: PlatformId
  siteId: SiteId
  isWebview: boolean
  isMobile: boolean
  isDesktop: boolean
  contextual?: Contextual
  entity?: Entity
}

type ProviderProps = PageContextType & {
  children: JSX.Element[] | JSX.Element
}

const PageContext = createContext<PageContextType | undefined>(undefined);

const usePage = (): PageContextType | undefined => useContext(PageContext);

const PageProvider = (props: ProviderProps): JSX.Element => {
  const { children, platformId, siteId, isWebview, isMobile, isDesktop, contextual, entity } = props;

  return (
    <PageContext.Provider value={{ platformId, siteId, isWebview, isMobile, isDesktop, contextual, entity }}>
      {children}
    </PageContext.Provider>
  );
};

export { PageProvider, PageContext, usePage };
