import { createContext, useState, useContext, ReactNode } from 'react';
import { merge } from 'lodash';
import { type LandingConfiguration, landingConfiguration } from './landingConfiguration';

export type LandingContexProps = {
  landingConfiguration: LandingConfiguration
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>
  isChecked: boolean
}

type LandingProviderProps = {
  children: ReactNode
  config?: Partial<LandingConfiguration>
}

const LandingContext = createContext({} as LandingContexProps);

export const useLanding = (): LandingContexProps => useContext(LandingContext);

export const LandingProvider = (props: LandingProviderProps): JSX.Element => {
  const { children, config } = props;
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [configuration, setConfiguration] = useState<LandingConfiguration>(
    merge({ ...landingConfiguration }, config),
  );

  return (
    <LandingContext.Provider
      value={{
        landingConfiguration: configuration,
        isChecked,
        setIsChecked,
      }}
    >
      {children}
    </LandingContext.Provider>
  );
};
