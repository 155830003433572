import { Button } from '@andes/button';
import { type PageContextType, usePage } from '../../contexts/PageContext';

export type Props = {
  id: string
  href?: string
  onClick?: () => void
  isDisabled?: boolean
  wording?: string
  hierarchy?: 'loud' | 'quiet' | 'transparent'
}

export const DefaultButton = (props: Props): JSX.Element => {
  const { id, href, onClick, isDisabled = false, wording = 'Continuar', hierarchy = 'loud' } = props;
  const { isMobile } = usePage() as PageContextType;

  return (
    <Button
      id={id}
      href={href}
      onClick={onClick}
      disabled={isDisabled}
      fullWidth={isMobile}
      hierarchy={hierarchy}
      data-cy="button"
    >
      { wording }
    </Button>
  );
};
