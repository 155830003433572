import { hydrateRoot } from 'nordic/hydrate';

import { AndesProvider } from '@andes/context';
import CommonLandingView, { type CommonLandingViewProps } from '../../../../components/common-view';
import { LandingProvider } from '../../../../contexts/LandingContext';

const props = window.__PRELOADED_STATE__ as CommonLandingViewProps;

hydrateRoot(document.getElementById('root-app')!,
  <AndesProvider locale={props.locale}>
    <LandingProvider config={props.config}>
      <CommonLandingView {...props} />
    </LandingProvider>
  </AndesProvider>);
